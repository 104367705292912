<template>
  <div class="applicants">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <ApplicantTable />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import ApplicantTable from '@/components/ApplicantTable';

export default {
  name: 'Applicants',
  components: {
    Breadcrumbs,
    ApplicantTable,
  },
  data() {
    return {};
  },
  computed: {
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('applicants') }];
    },
  },

  methods: {},

  async mounted() {},
};
</script>

<style scoped lang="scss"></style>
