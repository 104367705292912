<template>
  <div class="applicants">
    <Dialog header="Upload" :visible.sync="showUploadOverlay">
      <DropzoneUpload
        :complete="isAttachmentUploadComplete"
        :parentId="uploadApplicantId"
        parentType="applicantAttachments"
        :uploadCallback="uploadApplicantAttachment"
        v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
      />
    </Dialog>

    <DataTable
      class="p-datatable-sm"
      :value="applicants"
      :loading="isLoading"
      editMode="cell"
      :rowClass="rowClass"
      :rowHover="true"
      dataKey="number"
      :lazy="true"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="filterName"
      filterDisplay="row"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :totalRecords="getApplicantCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[20, 50, 100]"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left"></div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>

            <div class="button-right">
              <button class="btn btn-success mb-2 right" @click="onCreateApplicant">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('new') }}
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.number === '_new'">{{ $t(slotProps.data.number) }}</span>
          <span v-else>
            {{ slotProps.data.number.toString().padStart(5, '0') }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="firstname" :header="$t('firstname')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="lastname" :header="$t('lastname')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="position" :header="$t('position')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
      <Column field="locationOfDeployment" :header="$t('locationOfDeployment')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
      <Column field="role" :header="$t('applicantRole')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="applicantState"
        :header="$t('applicantState')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getApplicantStateColor(slotProps.data.applicantState)">
            {{ $t(slotProps.data.applicantState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('ApplicantState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getApplicantStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getApplicantStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.applicantStates"
            :options="applicantStates"
            @input="onApplicantStateFilter"
            optionLabel="label"
            :placeholder="$t('applicantState')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column field="notes" :header="$t('notes')" sortable :styles="{ width: '20%' }">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="phone" :header="$t('phone')" sortable :styles="{ width: '20%' }">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="zip" :header="$t('zip')" sortable :styles="{ width: '20%' }">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
      <Column
        field="startOfWork"
        :header="$t('startOfWork')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          <span>
            {{
              $dayjs(slotProps.data[slotProps.column.field]).isValid()
                ? $dayjs(slotProps.data[slotProps.column.field]).format('D.M.YYYY')
                : ''
            }}
          </span>
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          ></Calendar>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          ></Calendar>
        </template>
      </Column>
      <Column field="applicantAttachments" :header="$t('attachments')" :styles="{ width: '25%' }">
        <template #body="slotProps">
          <div class="attachment-grid">
            <AttachmentList
              :attachments="slotProps.data.applicantAttachments"
              :parentId="slotProps.data.id"
              attachmentType="applicantAttachments"
              v-on:attachment-list:delete="onDeleteApplicantAttachment"
            ></AttachmentList>

            <Button
              class="mt-2 p-button-secondary grid-item"
              icon="fa fa-upload"
              @click="onShowUploadDialog(slotProps.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Multiselect from 'vue-multiselect';
import MultiSelect from 'primevue/multiselect';
import { getApplicantStateColor } from '@/helpers/colors';
import { FilterMatchMode } from 'primevue/api';
import AttachmentList from '@/components/AttachmentList';
import DropzoneUpload from '@/components/DropzoneUpload';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default {
  name: 'ApplicantTable',
  components: {
    DataTable,
    Column,
    InputText,
    Calendar,
    Dropdown,
    // Multiselect,
    MultiSelect,
    AttachmentList,
    DropzoneUpload,
    Button,
    Dialog,
  },
  props: {},
  data() {
    return {
      showUploadOverlay: false,
      uploadApplicantId: null,
      isAttachmentUploadComplete: false,
      filteredUsers: [],

      /** persistent table filters  */
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        zip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        locationOfDeployment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        role: { value: null, matchMode: FilterMatchMode.CONTAINS },
        startOfWork: {
          value: this.$dayjs()
            .startOf('week')
            .toDate(),
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
      },
      defaultCustomFilters: {
        applicantStates: [],
      },

      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getApplicants',
      'getEnumValues',
      'getProjects',
      'getUsers',
      'getApplicantCount',
    ]),

    applicants() {
      return this.getApplicants;
    },
    applicantStates() {
      const applicantStates = this.getEnumValues('ApplicantState').map((applicantState) => {
        return { value: applicantState, label: this.$t(applicantState) };
      });
      return applicantStates;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },

    filterName() {
      return 'applicants-table-filters';
    },

    customFilterName() {
      return 'applicants-custom-table-filters';
    },
  },

  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchApplicants',
      'createApplicant',
      'updateApplicant',
      'initApplicant',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'deleteApplicant',
      'sendApplicantMail',
      'fetchUsers',
      'deleteApplicantAttachment',
      'uploadApplicantAttachment',
    ]),

    onShowUploadDialog(applicantId) {
      console.log(applicantId);
      this.uploadApplicantId = applicantId;
      // this.$refs.op.toggle(event);
      this.showUploadOverlay = !this.showUploadOverlay;
    },

    /**
     * Load remote table data
     */
    async loadApplicantData() {
      await this.fetchApplicants({
        page: this.pageOffset,
        pageSize: this.tableState.pagination.rowsPerPage,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });

      this.filteredProjects = this.getProjects;
    },

    onCreateApplicant() {
      this.initApplicant();
    },

    /**
     * send applicant data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      // console.log('onCellEditComplete():', event);
      data[field] = newValue;
      // console.log(data[field], newValue, field);
      this.save(data);
    },

    async save(applicant) {
      // console.log(applicant);

      if (applicant.number === '_new') {
        await this.createApplicant(applicant);
      } else {
        await this.updateApplicant(applicant);
      }
    },

    /**
     * Reset filter settings
     */
    async onRemoveFilters(event) {
      this.tableState.filters = this.defaultFilters;
      this.tableState.customFilters = this.defaultCustomFilters;

      localStorage.removeItem(this.filterName);
      localStorage.removeItem(this.customFilterName);
      await this.loadApplicantData();
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      // console.log('onPage', event);
      this.loadApplicantData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadApplicantData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadApplicantData();
    },

    /**
     * restore filters from local storage
     * @param {*} event
     */
    async onStateRestore(event) {
      // console.log('onStateRestore', event);

      this.setupFilters();
      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.customFilterName));
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
      // console.log('onStateRestore', this.tableState.customFilters);
    },

    setupFilters() {
      // console.log('setupFilters', this.tableState);

      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 50;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },
    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    async onApplicantStateFilter(value) {
      this.tableState.customFilters.applicantStates = this.applicantStates.filter(
        (applicantState) => value.includes(applicantState)
      );
      localStorage.setItem(this.customFilterName, JSON.stringify(this.tableState.customFilters));
      await this.loadApplicantData();
    },

    onDeleteApplicantAttachment(event) {
      this.deleteApplicantAttachment({
        applicantId: event.parentId,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },

    getApplicantStateColor,
  },
  async created() {
    this.setupFilters();

    // console.log('created()', this.tableState);
  },
  async mounted() {
    await this.fetchEnumValues('ApplicantState');
    await this.loadApplicantData();

    /** after upload is complete emit event to clear dropzone preview */
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadApplicantAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.attachment-grid {
  display: grid;
  grid-template-columns: auto 30px;
  gap: 1em;
  // border: 1px solid gray;
}
.grid-item {
  align-self: start; /* Align the button to the top of its grid cell */
  justify-self: end; /* Align the button to the right of its grid cell */
  /* Your styles for the button */
}

::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-users {
  min-width: 230px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-users .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
</style>
